<template>
  <b-card>
    <b-overlay :show="loading">
      <b-row>
        <b-col md="6" sm="12" class="my-1">
          <b-button v-b-modal.modal-tambah variant="primary" v-if="allowCreate()" @click="add()">
            <feather-icon icon="PlusIcon" class="mr-50" />
            Add
          </b-button>
          <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModal" id="modal-" ok-title="Tutup"
            ok-variant="secondary" ok-only centered title="Form ">
            <validation-observer ref="formbiaya_entertain">
              <div class="d-flex">
                <feather-icon icon="PackageIcon" size="19" />
                <h6 class="ml-50 mb-2">Data Biaya Entertain</h6>
              </div>
              <b-form>
                <b-row>
                  <!-- Tanggal  -->
                  <b-col cols="12">
                    <b-form-group label="Tanggal " label-for="v-tanggal">
                      <validation-provider #default="{ errors }" name="tanggal" rules="required">
                        <flat-pickr v-model="form.tanggal" class="form-control"
                          :config="{ enableTime: false, dateFormat: 'Y-m-d' }" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- judul -->
                  <b-col cols="12">
                    <b-form-group label="Judul" label-for="v-judul">
                      <b-form-input v-model="form.judul" id="v-judul" placeholder="Isi Judul" />
                    </b-form-group>
                  </b-col>

                  <!-- keterangan -->
                  <b-col cols="12">
                    <b-form-group label="Keterangan" label-for="v-keterangan">
                      <b-form-textarea v-model="form.keterangan" id="v-keterangan" placeholder="Isi Keterangan" />
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submit"
                      variant="primary" class="mr-1">
                      Simpan
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
        <b-col md="6" sm="12" class="my-1">
          <h2 class="text-danger text-right">
            Total: <strong>Rp {{ formatRupiah(totalBiaya) }}</strong>
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <!-- <b-col md="4" sm="8" class="my-1">
          <b-form-group class="w-100">
            <div class="d-flex align-items-center w-100">
              <label class="d-inline-block text-sm-left mr-50">Status </label>
              <b-form-select
                id="perPageSelect"
                v-model="selectedStatus"
                :options="statusOptions"
                class="w-50"
              />
            </div>
          </b-form-group>
        </b-col> -->
        <b-col md="6" class="my-1">
          <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0">
            <b-input-group>
              <b-form-input id="filterInput" v-model="filter" type="search"  />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="isFinance">
          <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fieldsFinance" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(id_akun)="data">
            <b-badge v-if="data.item.id_akun" variant="light-info">
              {{ getnamaakun(data.item)  }}
            </b-badge>
            <i class="text-danger" v-else><small>Akun belum dipilih</small></i>
          </template>
          
          <template #cell(id_kas)="data">
            <b-badge v-if="data.item.id_kas" variant="light-info">
              {{ getnamakas(data.item)  }}
            </b-badge>
            <i class="text-danger" v-else><small>Kas belum dipilih</small></i>
          </template>
            <template #cell(diberikan)="data">
              <b-badge :variant="iberi[1][data.value]">
                {{ iberi[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(tanggal)="{ item }">
              {{humanDate(item.tanggal)}}
            </template>
            <template #cell(total)="{ item }">
                  Rp. {{ formatRupiah(item.total) }}
                </template>
            <template #cell(diterima)="data">
              <b-badge :variant="iterima[1][data.value]">
                {{ iterima[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <b-button title="'Print / Cetak'" size="sm" @click.prevent="print(row.item)"
                variant="outline-warning" class="mr-1">
                <feather-icon icon="PrinterIcon"></feather-icon>
              </b-button>
              <b-button title="'Detail'" size="sm"
                @click.prevent="$router.push(`/biaya-entertain/detail/${row.item.id}`)" class="mr-1"
                variant="outline-success">
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button title="'Ubah'" v-if="allowUpdate() && row.item.selesai == 0" size="sm"
                @click="edit(row.item)" class="mr-1" variant="outline-info">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button v-if="allowDelete() && row.item.selesai == 0" title="'Hapus'" size="sm"
                @click="remove(row.item)" class="mr-1" variant="outline-danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BForm,
  BTable,
  BCard,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selectedStatus(status) {
      if (status) {
        this.getData();
      }
    },
  },
  data() {
    return {
      loading: false,
      statusOptions: [
        {
          value: "all",
          text: "Semua",
        },
      ],
      selectedStatus: "all",
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        judul: "",
        tanggal: this.getCurrentDate(),
        keterangan: "",
      },
      id_gudang: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      iberi: [
        {
          1: "Setuju",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      iterima: [
        {
          1: "Terima",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      fieldsFinance: [
        {
          key: "no",
          label: "#",
        },
        { key: "tanggal", label: "Tgl ", sortable: true },
        { key: "judul", label: "Judul" },
        { key: "keterangan", label: "Keterangan" },
        { key: "id_akun", label: "akun" },
        { key: "id_kas", label: "kas" },
        { key: "total", label: "Total" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      id_akun:[],
      id_kas:[],
    };
  },
  computed: {
    totalBiaya() {
      return this.items.reduce((total, item) => total += item.total, 0)
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsFinance
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // if (!this.isFinance) {
    //   // if sales, does not choose sales
    //   this.getid_gudang();
    // }
    this.getData();
    // this.getStatus();
    // if (!this.isAdminGudang) {
    //   // if sales, does not choose sales
    //   this.getid_gudang();
    // }
  },
  methods: {
    async print(item) {
      try {

        this.loading = true
        const response = await this.$store.dispatch("biaya_entertain/print", item.id);
        this.loading = false
        window.open(URL.createObjectURL(response))
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    // downloadAttachment(ajuan_id) {
    //   window.open(`/pengambilan-barang/download-attachment/${ajuan_id}`, "_blank");
    // },
    setStatus(status) {
      let result = {
        color: "primary",
        text: "",
      };
      switch (status) {
        case null:
        case 1:
          result = {
            color: "danger",
            text: "PENDING",
          };
          break;
        case 2:
          result = {
            color: "info",
            text: "DISETUJUI SPV",
          };
          break;
        case 3:
          result = {
            color: "dark",
            text: "DISETUJUI GUDANG",
          };
          break;
        case 4:
          result = {
            color: "success",
            text: "SELESAI",
          };
          break;
      }

      return result;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      if (this.form.sales && !this.isAdminGudang)
        this.form.id_gudang = this.form.sales.id;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: "Data ajuan barang ini akan dihapus",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("biaya_entertain/save", [item])
            .then(() => {
              this.getData();
              this.displaySuccess({
                message: " berhasil dihapus",
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
      this.form.tanggal = new Date();
      this.form.keterangan = null;
      if (!this.isAdminGudang) {
        this.form.id_gudang = null;
      }
    },
    submit() {
      this.$refs.formbiaya_entertain.validate().then(async (success) => {
        if (success) {
          if (this.id) {
            this.form.id = this.id;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("biaya_entertain/save", [payload]);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                message: " berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                message: " berhasil disimpan",
              });
              this.$router.push(`/biaya-entertain/detail/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getData() {
    this.getKas()
    this.getakun()
      // set payload if sales itself, filter per sales itself
      let params = this.isAdminGudang ? { id_gudang: this.myGudang.id, order: "desc" } : { selesai: 1, order:"desc" };

      // if (this.selectedStatus && this.selectedStatus != "all") {
      //   params.status = this.selectedStatus;
      // }
      this.loading = true;
      const ajuans = await this.$store.dispatch("biaya_entertain/getData", params);
      this.loading = false;
      this.items = ajuans;
      this.totalRows = ajuans.length;
    },
    async getakun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akun = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akun.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_akun = akun;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let kas = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          kas.map((item) => {
            item.value = item.id;
            const saldo = this.formatRupiah(item.saldo)
            item.text = `${item.nama} -> Rp ${saldo}`
          });
          this.id_kas = kas;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    // getsalesName(data) {
    //   return data.sales ? data.sales.nama_lengkap : "-";
    // },
    
    getnamaakun(data) {
      let find = this.id_akun.find(item => item.id == data.id_akun)
      return find ? find.nama : '-'
    },
    getnamakas(data) {
      let find = this.id_kas.find(item => item.id == data.id_kas)
      return find ? find.nama : '-'
    },
    getid_gudang() {
            let payload = {jabatan_id: 5}
      if (this.isCabang){
        payload.member_id = this.user.member_id
      }
      this.$store
        .dispatch("karyawan/getData", payload)
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });

          this.id_gudang = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      return data.sales ? data.sales.nama_lengkap : "-";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
